import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import {HelpersService} from "../shared/services/helpers.service";
import {LocalStorageService} from "../shared/services/local-storage.service";
import {NDCApiService} from "../shared/services/ndc-api.service";
import {AuthService} from "../shared/services/auth.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {Dictionary} from "../shared/types/dictionary";
import {filter, takeUntil} from "rxjs/operators";
import {UmbrellaService} from "../shared/services/umbrella.service";
import {DataTransferService} from "../shared/services/data-transfer.service";
import {environment} from "../../environments/environment";
import { Subject } from 'rxjs';
import {DateService} from "../shared/services/date.service";
import moment from 'moment';
import {Agency} from "../shared/types/agency";
import {HubService} from "../shared/services/hub.service";
import {NotificationService} from "../shared/services/notification.service";
import {HubAgenciesService} from "../shared/services/hub-agencies.service";
import {TimezoneService} from "../shared/services/timezone.service";


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() frontendSelectedDependency;
  currencies = [];
  selectedCurrency: string;
  selectedTimezone: string;

  isSearchPage = false;
  isOrdersPage = false;
  isCustomerOrdersPage = false;
  isCorporatesPage = false;
  isAgenciesPage = false;
  isAgentsPage = false;
  viewOpts: any;

  selectedDateFormat;
  selectedTelCountryNumber;

  currentDate = moment().toISOString();

  maxOffersPerCabin: number;

  selectedStopsAmount: any;
  stopDuringFlight = [
    {title: 'Non-stop', value: 0},
    {title: '1 Stop', value: 1},
    {title: '2 Stops or More', value: 10}];

  currentAgency: Agency;
  agencies: Agency[] = [];

  selectDisabled = false;

  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(public helpers: HelpersService,
              public ls: LocalStorageService,
              public service: NDCApiService,
              public authService: AuthService,
              public dataTransferService: DataTransferService,
              public modalService: NgbModal,
              private router: Router,
              public umbrellaService: UmbrellaService,
              public dateService: DateService,
              private hubService: HubService,
              private hubAgenciesService: HubAgenciesService,
              private _notificationSvc: NotificationService,
              private timezoneService: TimezoneService,
              private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.selectedTimezone = this.timezoneService.getDefaultTimezone();
    this.selectedStopsAmount = this.stopDuringFlight.find(item => item.value === this.ls.settings.stops) || this.stopDuringFlight[2];
    this.currencies = Dictionary.Currencies;
    this.selectedDateFormat = this.dateService.dateFormat ?? this.dateService.dateFormatOptions[0];

    this.isSearchPage = this.router.isActive('search', false);
    this.isOrdersPage = this.router.isActive('orders', false) &&
      this.router.url.split('?').shift() === '/orders';
    this.isCustomerOrdersPage = this.router.isActive('subagencies', false) &&
      this.router.url.split('?').shift() === '/subagencies';
    this.isCorporatesPage = this.router.isActive('corporates', false) &&
      this.router.url.split('?').shift() === '/corporates';
    this.isAgenciesPage = this.router.isActive('agencies', false) &&
      this.router.url.split('?').shift() === '/agencies';
    this.isAgentsPage = this.router.isActive('agents', false) &&
      this.router.url.split('?').shift() === '/agents';

    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((event: NavigationEnd) => {
        this.isSearchPage = false;
        this.isOrdersPage = false;
        this.isCustomerOrdersPage = false;
        this.isCorporatesPage = false;
        this.isAgenciesPage = false;
        this.isAgentsPage = false;
        if (event.url.startsWith('/search')) {
          this.isSearchPage = true;
        }
        if (event.url.includes('orders') && event.url.split('?').shift() === '/orders') {
          this.isOrdersPage = true;
        }
        if (event.url.includes('subagencies') && event.url.split('?').shift() === '/subagencies') {
          this.isCustomerOrdersPage = true;
        }
        if (event.url.startsWith('/corporates')) {
          this.isCorporatesPage = true;
        }
        if (event.url.includes('agents') && event.url.split('?').shift() === '/agents') {
          this.isAgentsPage = true;
        }
        if (event.url.includes('agencies') && event.url.split('?').shift() === '/agencies') {
          this.isAgenciesPage = true;
        }
        this.setViewOpts();
        this.cdr.markForCheck();
      });

    this.setViewOpts();
    this.maxOffersPerCabin = this.ls.settings?.maxOffersPerCabin || 1;

    this.authService.isUserDataReceived$.subscribe(isReceived => {
      if (!!isReceived) {
        this.loadProviderList();
        this.hubAgenciesService.refreshPresets();
      }
      this.getAgencies();
    });
  }

  navigate(url, event) {
    if (event.ctrlKey || event.metaKey) {
      window.open(url, "_blank");
    } else {
      this.helpers.go(url);
    }
  }

  ngAfterViewInit(): void {
    if (location.pathname === '/') {
      this.helpers.go('/search');
    }

    this.router.events
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(event => {
      if (event instanceof NavigationStart) {
        this.selectDisabled = !event.url.includes('/search');

        const notificationMessage = this.ls.notificationMessage;
        if (notificationMessage) {
          this._notificationSvc.success('Success', notificationMessage);
          this.ls.removeNotificationMessage();
        }
      }
    });
  }

  changeSettings(currency: string, enableLimitOfOffers: boolean) {
    let reloadPage = false;
    if (this.ls?.settings?.currency !== currency) {
      reloadPage = true;
    }

    let settings = this.ls.settings;
    settings['currency'] = currency;
    settings['enableLimitOfOffers'] = enableLimitOfOffers;
    settings['maxOffersPerCabin'] = this.maxOffersPerCabin;
    settings['stops'] = this.selectedStopsAmount.value;
    settings['telCountryNumber'] = this.selectedTelCountryNumber;

    this.ls.settings = settings;
    this.selectedCurrency = currency;
    this.dateService.dateFormat = this.selectedDateFormat;
    if (reloadPage) {
      location.reload();
    } else {
      this.dateService.setDateFormat(this.dateService.dateFormat);
      this.timezoneService.setTimezone(this.selectedTimezone);
      this.helpers.changeMaxStops(this.selectedStopsAmount.value);
    }
  }

  open(content) {
    this.modalService.open(content, {
      size: 'lg',
    }).result.then((result) => {
    }, (reason) => {
    });
  }

  openSettings(settingsModal) {
    this.selectedCurrency = this.ls.settings?.currency || this.helpers.defaultCurrency;
    this.open(settingsModal);
  }

  onCountryChange(event) {
    this.selectedTelCountryNumber = {iso: event.iso2, dialCode: event.dialCode};
  }

  telInputObject($event: any) {
    let telInputObjects = $event;
    this.helpers.onTelInputObject(telInputObjects);
  }

  logout() {
    this.authService.logout();
  }

  umbrellaLogOut() {
    this.dataTransferService.umbrellaLogOut$.next(true);
    this.ls.resetHrefLink();
    this.ls.resetUmbrellaStorage();
  }

  showDefaultLogo(event) {
    const defaultImg = environment.default.logoName;
    event.target.src = 'assets/img/consumers/portals/' + defaultImg;
  }

  setViewOpts() {
    this.viewOpts = {
      logo: this.frontendSelectedDependency.logoName,
      search: this.frontendSelectedDependency.showSearch && this.isAgent(),
      orders: this.frontendSelectedDependency.showOrders && this.isAgent(),
      subagencies: this.ls.isSubagenciesAvailable &&
        this.ls.isSuperagency === 'true' &&
        this.frontendSelectedDependency.showSubagencies &&
        this.isAgent(),
      user: this.frontendSelectedDependency.showSettings,
      settings: this.isAgent(),
      corporates: this.frontendSelectedDependency.showCorporates && this.isAgent() && this.ls.profilesType === 'airgateway',
      agencies: this.isManager() && this.isAgent(),
      agents: (this.isManager() || this.ls.role === 'subagency_manager') && this.isAgent(),
      presets: this.isManager() && this.isAgent(),
    };
  }

  setDateFormat(selectedDateFormat) {
    this.selectedDateFormat = selectedDateFormat;
  }

  setTimezone(timezone: string): void {
    this.selectedTimezone = timezone;
  }

  setSelectCurrency(selectedCurrency) {
    this.selectedCurrency = selectedCurrency;
  }

  setSelectStops(value) {
    this.selectedStopsAmount = value;
  }

  onChangeMaxOffersPerCabin(offersPerCabin: number) {
    this.maxOffersPerCabin = offersPerCabin;
  }

  loadProviderList() {
    if (this.ls.token) {
      this.service.sendProviderList()
        .then((response: any) => {
          if (response?.providers) {
            this.helpers.allProviders = [...new Set(response.providers)];
          } else {
            this.helpers.allProviders = [];
          }

          if (this.helpers.allProviders.length) {
            this.helpers.allProvidersObj = this.helpers.allProviders
              .sort()
              .reduce((result, key) => {
                if (Dictionary.ProvidersMapping[key]) {
                  result[key] = Dictionary.ProvidersMapping[key];
                }
                return result;
              }, {});
          }
          this.helpers.isProvidersLoaded.next(true);
        })
        .catch((response) => {
          if (this.helpers.isCriticalError(response)) {
            throw response;
          }
        });
    }
  }

  switchAgency(agency: Agency) {
    this.ls.agency = agency.name;
    this.ls.notificationMessage = 'Agency switched';
    window.location.reload();
  }

  getAgencies() {
    this.hubService.getAgencies().then((res: any) => {
      if (res && res.Data?.length) {
        this.currentAgency = res.Data.find((agency: Agency) => agency.id === this.ls.agencyID);
        this.agencies = res.Data.sort((a: Agency, b: Agency) => {
          if (a.id === this.currentAgency?.id) { return -1; }
          if (b.id === this.currentAgency?.id) { return 1; }
          return a.name.localeCompare(b.name);
        });
        this.cdr.markForCheck();
      }
    }).catch(err => {
        if (this.helpers.isCriticalError(err)) {
          throw err;
        }
      });
  }

  updatePassword() {
    const redirectUri = encodeURIComponent(window.location.href);
    window.location.href = `${environment.keycloakUrl}/realms/agents/protocol/openid-connect/auth?client_id=bookingpad&redirect_uri=${redirectUri}&response_type=code&scope=openid&kc_action=UPDATE_PASSWORD`;
  }

  openAccountSettings() {
    const url = `${environment.keycloakUrl}/realms/agents/account/#/security/signingin`;
    window.open(url, '_blank');
  }

  private isAgent() {
    return this.ls.userType === 'agent';
  }

  private isManager() {
    return this.ls.role === 'manager';
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
