import { Pipe, PipeTransform } from '@angular/core';
import {Dictionary} from "../types/dictionary";
import { HelpersService } from "../services/helpers.service";

@Pipe({
  name: 'filterAirlines',
})
export class FilterAirlinesPipe implements PipeTransform {

  constructor(private helpers: HelpersService) {
  }

  transform(value: any, filterKey: string, isProviders = false): any[] {
    const filteredAirlines = [];
    const airlines = isProviders ? this.helpers.allProvidersObj : Dictionary.AirlineDesignators;
    for (const airline in airlines) {
      if (airline?.toUpperCase().includes(filterKey?.toUpperCase()) ||
        airlines[airline]?.toUpperCase().includes(filterKey?.toUpperCase())) {
        filteredAirlines.push({id: airline, name: airlines[airline]});
      }
    }

    return filteredAirlines;
  }

}
