import {
  Component, ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output, QueryList,
  SimpleChanges, ViewChild, ViewChildren
} from "@angular/core";
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {NgbDate, NgbDateStruct, NgbInputDatepicker, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgxIndexedDBService} from 'ngx-indexed-db';
import {Dictionary, ErrorTypes} from '../../shared/types/dictionary';
import {LocalStorageService} from '../../shared/services/local-storage.service';
import {HelpersService} from '../../shared/services/helpers.service';
import {NDCApiService} from '../../shared/services/ndc-api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SearchRow} from '../../shared/models/search-row.model';
import moment from 'moment';
import {OfferService} from '../../shared/services/offer.service';
import {MinDates, OriginDestination, SearchModel } from '../../shared/models/search-model.model';
import {FLIGHT_TYPE, ROLES, URL_PARAMS} from '../../shared/constants';
import {ALL_RESET_KEYS_OBJ, SEARCH_TYPE} from '../constants';
import {NotificationService} from '../../shared/services/notification.service';
import {combineLatest, of, Subject, Subscription } from "rxjs";
import { filter, map, take, takeUntil, tap } from "rxjs/operators";
import {CorporateDiscountCodesService} from '../../shared/services/corporate-discount-codes.service';
import {SelectedCorporate} from '../../shared/types/corporate';
import {GtmService} from '../../shared/services/gtm.service';
import {SentryService} from '../../shared/services/sentry.service';
import {DateService} from "../../shared/services/date.service";
import {Preset} from "../../shared/interfaces/presets";
import {AuthService} from "../../shared/services/auth.service";
import { fadeInOnEnterAnimation, rotateAnimation } from 'angular-animations';
import { interval } from 'rxjs';
import {KeycloakService} from "keycloak-angular";
import {HubAgenciesService} from "../../shared/services/hub-agencies.service";
import { ProfilesService } from "../../shared/services/profiles.service";
import { UmbrellaService } from "../../shared/services/umbrella.service";
import {VirtualScrollSelectComponent} from "../../shared/components/virtual-scroll-select/virtual-scroll-select.component";


@Component({
  selector: 'app-offer-search',
  templateUrl: './offer-search.component.html',
  styleUrls: ['./offer-search.component.scss'],
  animations: [
    fadeInOnEnterAnimation({ duration: 500, delay: 200 }),
    rotateAnimation({ degrees: 360, duration: 1000 }),
  ]
})
export class OfferSearchComponent implements OnInit, OnChanges, OnDestroy {
  @Input() newOffersCount;
  @Input() isShowOffersForUpdateBtn;
  @Output() emitIsSearching = new EventEmitter();
  @Output() emitResetSidebarFilter = new EventEmitter();
  @Output() emitRefreshOffers = new EventEmitter();
  @Output() emitOriginDestinationsOnSearch: EventEmitter<any> = new EventEmitter<any>();
  // @Output() emitBuildDynamicSidebar = new EventEmitter();
  @Output() emitBuildStaticSidebar = new EventEmitter();
  @Output() emitResetFixedOriginDestinations = new EventEmitter();
  @ViewChild('dd') dd: NgbInputDatepicker;
  @ViewChild("arrivalDate") arrivalDate: ElementRef;
  @ViewChildren("departureDates") departureDates: QueryList<ElementRef>;
  @ViewChildren(VirtualScrollSelectComponent) virtualScrollSelects: QueryList<VirtualScrollSelectComponent>;

  MAX_TOTAL_COUNT_PASSENGERS = 9;

  SEARCH_TYPE = SEARCH_TYPE;
  searchType = SEARCH_TYPE.REGULAR;
  flightType;

  form: FormGroup;
  airports = Dictionary.getAirports();
  searchRows: SearchRow[] = [];
  travelersForOfferURL = '';
  originDestinationsForOfferURL = '';
  travelersCount = 1;
  controlIndexCounter = 0;
  defaultDepartureDate: NgbDateStruct;
  defaultReturnDate: NgbDateStruct;
  defaultMinDate: NgbDateStruct;
  selectedDepartureAirport = '';
  selectedArrivalAirport = '';
  loggedRequestTime = '';
  validateTriggered = false;
  isTokenUpdated = false;
  isRequestSent = false;
  allowNewSearch = true;
  currentFormCopy: any;
  searchRowsCopy: SearchRow[] = [];
  totalOffers = 0;
  errorMessage = '';
  minDates: MinDates;
  offers = [];
  currentDate;
  selectedFlightNumberArr = [];
  offersSubscription: Subscription;
  isDoneSearch = false;
  isShowUpdatePopup = true;
  searchClick = false;
  showUpdateSpinner = false;
  showErrorMatchAirports;

  abortController;
  abortSignal;
  defaultTravelers = {
    adt: 1,
    yad: 0,
    chd: 0,
    inf: 0,
  };

  ptcsMap = {
    ADT: 'adt',
    YAD: 'yad',
    CHD: 'chd',
    INF: 'inf'
  };

  cabinTypes = Dictionary.CabinTypes;
  urlParams = URL_PARAMS;

  searchTypeOptions = Dictionary.getSearchTypeOptions();
  selectedPreset: Preset;
  presetListRefreshToggled = false;
  canEditPresets: boolean;

  isNewPtc = false;
  largeFamilyList = Dictionary.getSearchTypeLargeFamily();
  residentsList = Dictionary.ResidentDiscountCodes;

  selectedProviders: string[] = [];

  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  selectedCorporate = '';
  selectedCorporateObj: SelectedCorporate | null = null;
  isChangeCorporate = true;

  selectedCompany = '';
  selectedCompanyObj: SelectedCorporate | null = null;
  isChangeCompany = true;

  isAddPassengerButtonDisable = false;
  isAddInfantButtonDisable = false;

  selectedTime: number[] = [];
  searchAgainOwner;

  offerData;
  webOffersHistoryData = [];
  dbService: NgxIndexedDBService;
  hoveredDate: NgbDateStruct;
  providerErrors: any = {};
  isFirstSearch = true;

  constructor(
    private fb: FormBuilder,
    public ls: LocalStorageService,
    public helpers: HelpersService,
    protected _notificationSvc: NotificationService,
    private service: NDCApiService,
    private router: Router,
    private route: ActivatedRoute,
    private corporateDiscountCodesService: CorporateDiscountCodesService,
    private gtmService: GtmService,
    private offerService: OfferService,
    private modalService: NgbModal,
    private sentryService: SentryService,
    public dateService: DateService,
    public authService: AuthService,
    public hubAgenciesService: HubAgenciesService,
    private profilesService: ProfilesService,
    private umbrellaService: UmbrellaService,
    private keycloakService: KeycloakService,
    private injector: Injector) {
    if (!this.helpers.isPrivateMode) {
      this.dbService = <NgxIndexedDBService>this.injector.get(NgxIndexedDBService);
    }
  }

  ngOnInit() {
    let todayDate = new Date();
    this.defaultMinDate = HelpersService.createStructWithChangeDays(todayDate);
    this.minDates = {
      'departure_date[0]': this.defaultMinDate,
    };
    this.defaultDepartureDate = HelpersService.createStructWithChangeDays(
      todayDate
    );
    this.defaultReturnDate = HelpersService.createStructWithChangeDays(todayDate);
    this.minDates['arrival_date[0]'] = HelpersService.createStructWithChangeDays(
      todayDate
    );
    this.flightType = this.ls.flightType || Dictionary.FLIGHT_TYPE_ROUND_TRIP;

    this.buildForm();

    combineLatest([
      this.hubAgenciesService.isPresetsLoaded$,
      this.helpers.isProvidersLoaded$
    ]).pipe(
      filter(([isPresetsLoaded, isProvidersLoaded]) => isPresetsLoaded && isProvidersLoaded) // Only proceed when both are true
    ).subscribe(() => {
      if (this.ls.providers?.length) {
        this.selectedProviders = this.ls.providers;
      }
      this.parseUrl();
    });

    this.setMinMaxDates();

    const currDate = new Date();
    this.currentDate = {
      year: currDate.getFullYear(),
      month: currDate.getMonth() + 1,
      day: currDate.getDate(),
    };
    this.getAllOffersAndRemoveOldHistory();
    this.getUpdatedDateFormat();

    this.canEditPresets = this.ls.role === ROLES.MANAGER || this.ls.role === ROLES.SUBAGENCY_MANAGER;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.selectedCabin &&
      !changes.selectedCabin.firstChange &&
      changes.selectedCabin.currentValue
    ) {
      this.form.controls['cabin'].setValue(changes.selectedCabin.currentValue);
    }
    if (changes.isShowOffersForUpdateBtn) {
      setTimeout(() => {
        this.showUpdateSpinner = false;
      }, 500);
    }
  }

  ngOnDestroy(): void {
    if (this.offersSubscription) {
      this.offersSubscription.unsubscribe();
    }
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  onSelectedCorporate(event) {
    if (event) {
      const corporate = JSON.parse(this.ls.profileCorporate);
      this.selectedCorporate = corporate.text;
      this.selectedCorporateObj = {
        name: corporate.text,
        id: corporate.id,
      };
      this.isChangeCorporate = false;
    } else {
      this.selectedCorporate = '';
      this.isChangeCorporate = true;
      this.selectedCorporateObj = null;
    }
  }

  onSelectedCompany(event) {
    if (this.ls.UmbrellaCompany && event) {
      let company = JSON.parse(this.ls.UmbrellaCompany);
      this.selectedCompany = company.text;
      this.selectedCompanyObj = {
        name: company.text,
        id: company.id,
      };
      this.isChangeCompany = false;
      this.fetchAndSetUmbrellaCorporateDiscountCodes(company.id);
    } else {
      this.selectedCompany = '';
      this.ls.removeUmbrellaCompany();
      this.isChangeCompany = true;
      this.selectedCompanyObj = null;
    }
  }

  fetchAndSetUmbrellaCorporateDiscountCodes(id: string) {
    this.umbrellaService.getCompanyData(id).subscribe((corporate: any) => {
      this.corporateDiscountCodesService.processUmbrellaCorporateCodes(corporate, id);
    }, error => {
      console.error(error);
    });
  }

  actionReloadTravelers() {
    this.form.controls['travelers'].setValue(this.defaultTravelers);
  }

  passengerAdd($event: MouseEvent, pType: string) {
    $event.preventDefault();
    let p = this.form.get('travelers').get(pType);
    let newValue = parseInt(p.value) + 1;
    if (newValue >= 0) {
      this.checkPassengerTotalCount(pType, newValue);
      p.setValue(newValue);
    }
  }

  passengerRemove($event: MouseEvent, pType: string) {
    $event.preventDefault();
    let p = this.form.get('travelers').get(pType);
    let newValue = parseInt(p.value) - 1;
    if (newValue >= 0) {
      this.checkPassengerTotalCount(pType, newValue);
      p.setValue(newValue);
    }
  }

  checkPassengerTotalCount(type, value) {
    const travelersForm = this.form.get('travelers');
    let count: any = {};
    Object.keys(travelersForm['controls']).map(key => {
      count[key] = parseInt(travelersForm.get(key).value);
    });

    count[type] = value;

    const adults = count[this.ptcsMap.ADT];
    const infants = count[this.ptcsMap.INF];

    if (infants > adults) {
      if (type === this.ptcsMap.INF) {
        this.form.get('travelers').patchValue({[this.ptcsMap.ADT]: infants});
      } else {
        this.form.get('travelers').patchValue({[this.ptcsMap.INF]: adults});
      }
    }

    this.isAddPassengerButtonDisable = this.getNumberOfPassengers(count) > this.MAX_TOTAL_COUNT_PASSENGERS - 1;
    this.isAddInfantButtonDisable = infants >= adults;

    if (this.isAddPassengerButtonDisable || this.isAddInfantButtonDisable) {
      return false;
    }

    return true;
  }

  getNumberOfPassengers(obj): number {
    let numberOfPassengers = 0;
    for (const el in obj) {
      if (obj.hasOwnProperty(el) && el !== this.ptcsMap.INF) {
        numberOfPassengers += parseFloat(obj[el]);
      }
    }
    return numberOfPassengers;
  }

  selectedCabinValue(cabinType) {
    this.form.controls['cabin'].setValue(cabinType);
  }

  private setTravelType(type) {
    switch (type) {
      case Dictionary.FLIGHT_TYPE_ONE_WAY:
      case Dictionary.FLIGHT_TYPE_ROUND_TRIP:
      case Dictionary.FLIGHT_TYPE_MULTI_CITY:
        return type;
      default:
        return Dictionary.FLIGHT_TYPE_ONE_WAY;
    }
  }

  onSelectValueChanged(controlName: string, value: string, idx: number) {
    this.form.controls[controlName].setValue(value.toUpperCase());
    if (controlName.startsWith('arrival') && this.searchRows.length - 1 > idx) {
      if (this.form.controls[`departure_code[${idx + 1}]`]) {
        this.form.controls[`departure_code[${idx + 1}]`].setValue(value);
      }
    }

    if (this.searchRows.length) {
      let temp;
      this.searchRows.map((item) => {
        const dep = this.form.controls[`departure_code[${item.controlIndex}]`]
          .value;
        const arr = this.form.controls[`arrival_code[${item.controlIndex}]`]
          .value;
        if (!!dep && !!arr && dep === arr) {
          temp = item.controlIndex;
          return;
        }
      });
      this.showErrorMatchAirports = temp;
    }
  }

  buildForm() {
    this.searchRows = [];
    this.form = this.fb.group({
      type: this.flightType,
      cabin: ['7'],
      residents: [''],
      large_family: [''],
      per_provider_limit: '',
      corporateSenderID: '',
      travelers: this.defaultTravelersFormGroup(),
    });

    this.actionAddRow();
  }

  defaultTravelersFormGroup() {
    return this.fb.group({
      adt: [1],
      yad: [0],
      chd: [0],
      inf: [0],
    });
  }

  actionAddRow() {
    this.initializeMinDateForNewRow();

    this.searchRows.push(<SearchRow>{ controlIndex: this.controlIndexCounter });
    let currentCounter = this.controlIndexCounter;

    this.updateFormRows(this.controlIndexCounter++, 'add');

    if (this.searchRows.length > 1) {
      this.copyPreviousArrivalToDeparture(currentCounter);
    }

    this.focusOnNewRow(currentCounter);
  }

  initializeMinDateForNewRow() {
    // init min date for next departure datetime picker
    const firstDateControl = this.form.controls[`departure_date[0]`];
    const previousDateControl = this.form.controls[`departure_date[${this.controlIndexCounter - 1}]`];
    if (previousDateControl && this.controlIndexCounter > 0) {
      this.minDates[`departure_date[${this.controlIndexCounter}]`] = HelpersService.createStructWithChangeDays(
        Object.keys(previousDateControl.value).length ? previousDateControl.value : firstDateControl.value,
        0
      );
    } else {
      this.minDates[`departure_date[${this.controlIndexCounter}]`] = this.minDates[`departure_date[${this.controlIndexCounter - 1}]`];
    }
  }

  copyPreviousArrivalToDeparture(currentCounter: number) {
    let previousArrivalCode = this.form.controls[`arrival_code[${this.searchRows[this.searchRows.length - 2].controlIndex}]`].value;
    this.form.controls[`departure_code[${currentCounter}]`].setValue(previousArrivalCode);
  }

  updateFormRows(i: number, scenario?: string) {
    let form = this.form;
    let action = 'addControl';
    if (scenario === 'remove') {
      action = 'removeControl';
    }

    let depCodeKey = `departure_code[${i}]`,
      depDateKey = `departure_date[${i}]`,
      arrCodeKey = `arrival_code[${i}]`,
      arrDateKey = `arrival_date[${i}]`;

    if (i === 0) {
      form[action](
        depCodeKey,
        new FormControl(this.selectedDepartureAirport, [])
      );
      form[action](depDateKey, new FormControl('', []));
      form[action](
        arrCodeKey,
        new FormControl(this.selectedArrivalAirport, [])
      );
      form[action](arrDateKey, new FormControl('', []));
    } else {
      form[action](depCodeKey, new FormControl('', []));
      form[action](depDateKey, new FormControl(<NgbDateStruct>{}, []));
      form[action](arrCodeKey, new FormControl('', []));
      form[action](arrDateKey, new FormControl('', []));
    }
  }

  focusOnNewRow(currentCounter: number) {
    // Focus the arrival select input after adding the flight row
    setTimeout(() => {
      const lastVirtualScrollSelect = this.virtualScrollSelects?.last;

      if (!lastVirtualScrollSelect) { return; }

      const departureControlValue = this.form.controls[`departure_code[${currentCounter}]`]?.value;
      const arrivalControlValue = this.form.controls[`arrival_code[${currentCounter}]`]?.value;

      if (departureControlValue && !arrivalControlValue) {
        lastVirtualScrollSelect.focusButton();
      }
    }, 0);
  }

  parseUrl() {
    let params = this.route.snapshot.queryParams;

    this.selectedFlightNumberArr = !!params['origin_destinations-flights']
      ? params['origin_destinations-flights'].split('-')
      : [];

    if (this.hubAgenciesService.mandatoryPreset) {
      this.selectedPreset = this.hubAgenciesService.mandatoryPreset;
    }

    if (this.hubAgenciesService.defaultPreset && !this.hubAgenciesService.mandatoryPreset) {
      this.selectedPreset = this.hubAgenciesService.defaultPreset;
    }

    if (!params.type) {
      return;
    }

    let corporateRequest$;
    if (params.corporate_id) {
      if (this.ls.profilesType === 'airgateway') {
        corporateRequest$ = this.profilesService.searchCorporates(params.corporate_id)
          .pipe(
            filter((res: any) => res?.body?.accounts?.length > 0),
            map((res: any) => res.body.accounts[0]),
            filter(corporate => !!corporate),
            tap(corporate => {
              this.selectedCorporate = corporate.name;
              this.selectedCorporateObj = { name: corporate.name, id: corporate.id };
              this.isChangeCorporate = false;
              this.corporateDiscountCodesService.processCorporateCodes(corporate);
            })
          );
      } else if (this.ls.profilesType === 'umbrella') {
        corporateRequest$ = this.umbrellaService.getCompanyData(params.corporate_id)
          .pipe(
            filter(corporate => !!corporate),
            tap(corporate => {
              this.selectedCompany = corporate.name;
              this.selectedCompanyObj = { name: corporate.name, id: corporate.uuid };
              this.isChangeCompany = false;
              this.corporateDiscountCodesService.processUmbrellaCorporateCodes(corporate, params.corporate_id);
            })
          );
      }
    }

    if (params.selected_times) {
      const splittedTimes = params.selected_times.split('-');
      this.form.addControl('time', new FormControl(splittedTimes));
      this.selectedTime = splittedTimes;
    }

    if (params.presetTitle && !this.hubAgenciesService.mandatoryPreset) {
      this.selectedPreset = this.hubAgenciesService.presets.find(preset => preset.title === params.presetTitle);
      if (this.selectedPreset) {
        this.prepareTravelersBasedOnSelectedPreset();
      }
    }

    if (params.providers) {
      this.selectedProviders = params.providers.split(',');
    }

    let idx = parseInt(params.idx) || 0;
    let travelers = this.form.controls['travelers'];
    Object.keys(travelers['controls']).map(key => {
      const prefix = 'travelers_';
      const value = parseInt(params[prefix + key]) || 0;
      travelers.get(key).setValue(value);
    });

    this.form.controls['type'].setValue(this.setTravelType(params.type));
    this.form.controls['cabin'].setValue(params.cabin || 0);
    this.form.controls['residents'].setValue(
      params.ib_discount_residents || ''
    );
    this.form.controls['large_family'].setValue(
      params.ib_discounts_large_family || ''
    );

    if (parseInt(params.per_provider_limit)) {
      this.form.controls['per_provider_limit'].setValue(
        parseInt(params.per_provider_limit)
      );
    }
    if (params.type.toString() === Dictionary.FLIGHT_TYPE_ROUND_TRIP) {
      let depDate = params['origin_destinations_date[0]'] || '';
      depDate = this.replacePastDateToCurrent(depDate);
      let depDateObj: NgbDateStruct = <NgbDateStruct>this.defaultDepartureDate;
      if (depDate) {
        let p = depDate.split('-');
        depDateObj = <NgbDateStruct>{
          year: parseInt(p[0]),
          month: parseInt(p[1]),
          day: parseInt(p[2]),
        };
      }

      let arrDate = params['origin_destinations_date[1]'] || '';
      let arrDateObj: NgbDateStruct = this.defaultDepartureDate;
      if (arrDate) {
        let pp = this.replacePastDateToCurrent(arrDate).split('-');
        arrDateObj = <NgbDateStruct>{
          year: parseInt(pp[0]),
          month: parseInt(pp[1]),
          day: parseInt(pp[2]),
        };
        this.minDates['arrival_date[0]'] = depDateObj;
      }

      this.form.controls['departure_code[0]'].setValue(
        params['origin_destinations_dep[0]']
          ? params['origin_destinations_dep[0]'].toString().toUpperCase()
          : ''
      );
      this.form.controls['arrival_code[0]'].setValue(
        params['origin_destinations_arr[0]']
          ? params['origin_destinations_arr[0]'].toString().toUpperCase()
          : ''
      );
      this.ensureAirportCodeForExistence(
        this.form.controls['departure_code[0]'].value
      );
      this.ensureAirportCodeForExistence(
        this.form.controls['arrival_code[0]'].value
      );
      this.form.controls['departure_date[0]'].setValue(depDateObj);
      this.form.controls['arrival_date[0]'].setValue(arrDateObj);
    } else {
      for (let i = 1; i <= idx; i++) {
        this.actionAddRow();
      }
      for (let i = 0; i <= idx; i++) {
        let date = this.replacePastDateToCurrent(
          params[`origin_destinations_date[${i}]`] || ''
        );
        let dateObj: NgbDateStruct = this.defaultDepartureDate;
        if (date) {
          let p = date.split('-');
          dateObj = <NgbDateStruct>{
            year: parseInt(p[0]),
            month: parseInt(p[1]),
            day: parseInt(p[2]),
          };
        }
        this.form.controls[`departure_code[${i}]`].setValue(
          params[`origin_destinations_dep[${i}]`]
            ? params[`origin_destinations_dep[${i}]`].toString().toUpperCase()
            : ''
        );
        this.form.controls[`arrival_code[${i}]`].setValue(
          params[`origin_destinations_arr[${i}]`]
            ? params[`origin_destinations_arr[${i}]`].toString().toUpperCase()
            : ''
        );
        this.ensureAirportCodeForExistence(
          this.form.controls[`departure_code[${i}]`].value
        );
        this.ensureAirportCodeForExistence(
          this.form.controls[`arrival_code[${i}]`].value
        );
        this.form.controls[`departure_date[${i}]`].setValue(dateObj);
      }
    }

    this.actionChangeTravelers();

    // Trigger the search after corporate data is loaded (if applicable) only if offers weren't fetched earlier
    (corporateRequest$ ? corporateRequest$ : of(null)).subscribe(() => {
      if (!this.offers.length) {
        this.actionSearch();
      }
    });
  }

  changeDate(rowIndex: number, index: number, increment: boolean, departure: boolean) {
    const depPrefix = 'departure_date[';
    const arrPrefix = 'arrival_date[';
    const prefix = departure ? depPrefix : arrPrefix;
    const control = this.form.get(`${prefix}${rowIndex}]`);
    const currentDate = NgbDate.from(control.value);
    const inDate = currentDate?.year ? currentDate : this.currentDate;
    const newDate = HelpersService.createStructWithChangeDays(inDate, 1, increment);

    if (departure && HelpersService.isFirstDateGreater(this.currentDate, newDate)) {
      return;
    }

    if (!departure) {
      const depDate = this.form.get(`${depPrefix}${rowIndex}]`)?.value;
      if (depDate && HelpersService.isFirstDateGreater(depDate, newDate)) {
        return;
      }
    }

    control.setValue(newDate);
    this.onDateDepartureSelect(rowIndex, index, newDate);
  }

  ensureAirportCodeForExistence(code) {
    let exists = false;
    this.airports.map((item) => {
      if (item.id === code) {
        exists = true;
      }
    });
    if (!exists) {
      this.airports.push({ id: code, city: '', text: code });
    }
  }

  actionChangeTravelers() {
    let form = this.form;
    let travelers = form.controls['travelers'];
    let count = 0;

    Object.keys(travelers['controls']).map(key => {
      if (travelers.get(key).value) {
        count += travelers.get(key).value;
      }
    });

    this.travelersCount = count;
  }

  clickSearch() {
    this.searchClick = true;
    this.emitIsSearching.emit(true);
    this.actionSearch([...this.getOriginDestinations()]);
    this.addOfferToHistory(this.offerData, this.searchType);
  }

  resetSearchAgain() {
    this.router.navigate([], {
      queryParams: {},
    });
    this.searchAgainOwner = '';

    setTimeout(() => this.actionSearch(), 0);
  }

  actionSearch(previousOD: any[] = []) {
    this.isShowUpdatePopup = true;
    this.isDoneSearch = false;
    let resetUrl = false;
    let params = this.route.snapshot.queryParams;
    this.emitResetFixedOriginDestinations.emit(true);
    if (!this.validate() || !this.multiCityDateValidator()) {
      this.allowNewSearch = true;
      this.validateTriggered = true;
      this.emitIsSearching.emit(false);
      return;
    }

    if (params.owner && params.owner !== '*') {
      this.searchAgainOwner = this.sentryService.owner = params.owner;
    }

    this.validateTriggered = false;

    let form = this.form;
    this.currentFormCopy = JSON.parse(JSON.stringify(this.form.value));
    this.searchRowsCopy = JSON.parse(JSON.stringify(this.searchRows));
    this.allowNewSearch = false;
    this.isRequestSent = false;
    this.loggedRequestTime = '';

    let originDestinations = this.getOriginDestinations();

    // reset filters in dashboard if need
    if (previousOD.length) {
      const prevOD = previousOD;
      const currOD = originDestinations;

      if (!prevOD.length) { return; }

      if (prevOD.length !== currOD.length) {
        resetUrl = true;
        this.emitOriginDestinationsOnSearch.emit(this.collectSearchObject());
      } else {
        prevOD.map((item, i) => {
          if (
            prevOD[i].arrival.airportCode !== currOD[i].arrival.airportCode ||
            prevOD[i].departure.airportCode !== currOD[i].departure.airportCode
          ) {
            resetUrl = true;
            this.emitOriginDestinationsOnSearch.emit(this.collectSearchObject());
          }
        });
      }
    }

    let currency = this.ls.settings.currency || this.helpers.defaultCurrency;
    let cabinTypes = [];
    let cabin = form.controls['cabin'].value;
    if (cabin !== '0') {
      cabinTypes = [cabin];
    }

    let selectedFare =
      !this.selectedPreset || (this.selectedPreset?.preference.length === 1 && this.selectedPreset?.preference[0].title === 'No Preference')
        ? []
        : this.selectedPreset.preference;
    let selectedDiscounts = {};
    let hasDiscounts = false;
    if (form.controls['large_family'].value) {
      hasDiscounts = true;
      selectedDiscounts['largeFamily'] = form.controls['large_family'].value;
    }
    if (form.controls['residents'].value) {
      hasDiscounts = true;
      selectedDiscounts['residentCode'] = form.controls['residents'].value;
    }

    const passengers = [];
    let travelers = form.controls['travelers'];
    let travelersPrepared = {};
    ['ADT', 'YAD', 'CHD', 'INF'].forEach(defaultType => {
      const travelerType = this.ptcsMap[defaultType];
      const traveler = parseInt(travelers.get(travelerType)?.value);
      if (traveler) {
        travelersPrepared[travelerType] = isNaN(traveler) ? 0 : traveler;
        for (let i = 0; i < traveler; i++) {
          let passenger: any = {
            travelerReference: (travelerType + i).toUpperCase(),
            passengerType: travelerType.toUpperCase(),
          };
          if (travelerType === this.ptcsMap.ADT && travelers.get(this.ptcsMap.INF)?.value >= i + 1) {
            passenger.infantReference = (this.ptcsMap.INF + i).toUpperCase();
          }
          passengers.push(passenger);
        }
      }
    });

    this.totalOffers = 0;
    this.errorMessage = '';

    this.offers = [];
    this.providerErrors = {};
    this.offerService.addProviderErrors(this.providerErrors);

    const buildParams = {
      searchType: form.controls['type'].value,
      travelers: travelersPrepared,
      cabin: cabin,
      presetTitle: this.selectedPreset?.title,
      originDestinations: originDestinations,
      operatingCarrier: params['operating_carrier'],
      marketingCarrier: params['marketing_carrier'],
      flightsNumber: params['origin_destinations-flights'],
      maxStops: params[this.urlParams.MAX_STOPS],
      ib_discount_residents: form.controls['residents'].value,
      ib_discounts_large_family: form.controls['large_family'].value,
      corporate_id: this.selectedCorporateObj?.id || this.selectedCompanyObj?.id,
      selectedProviders: this.selectedProviders
    };

    if ( this.selectedTime.length > 0 ) {
      buildParams['selected_times'] = this.selectedTime.join('-');
    }

    this.buildUrl(buildParams, resetUrl);
    let that = this;
    let startDate = new Date();
    let xhr = new XMLHttpRequest();

    let partChunk = '';

    let offers = [];
    let processOffers = () => {
      let offersList = offers.splice(0, offers.length);
      let totalOffers = offersList.length;
      that.totalOffers += totalOffers;
      that.offers.push(...offersList);
      this.offerService.addOffers(that.offers);
    };
    //this.offerService.pushSearchModel(this.collectSearchObject());
    this.emitResetSidebarFilter.emit(this.collectSearchObject());
    this.emitBuildStaticSidebar.emit(this.collectSearchObject());

    const interval$ = interval(2500);
    this.offersSubscription = interval$.subscribe(() => processOffers());


    // let buildSidebarInterval = setInterval(() => {
    //   //this.offerService.pushSearchModel(this.collectSearchObject());
    //   if (this.prevOffersCount !== this.totalOffers) {
    //     //this.offerService.pushSearchModel(this.collectSearchObject());
    //     this.emitBuildDynamicSidebar.emit(this.collectSearchObject());
    //     this.prevOffersCount = this.totalOffers;
    //   }
    // }, 2000);
    // Fetch the original image
    try {
      this.abortController = AbortController ? new AbortController() : null;
    } catch (e) {
      this.abortController = null;
      console.log(e);
      this._notificationSvc.warning(
        '',
        'You are using an outdated browser. Please use modern one.',
        'Environment Warning',
        0
      );
    }

    if (this.abortController) {
      this.abortSignal = this.abortController.signal;
    }

    const corporateSenderID = this.form.controls['corporateSenderID'].value;

    let discountCodes = null;
    let loyaltyProgramCodes = null;
    this.corporateDiscountCodesService.corporateCodes$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((codesByCorporateId) => {
        const selectedCorporateId = this.selectedCorporateObj?.id ?? this.selectedCompanyObj?.id;
        discountCodes = codesByCorporateId?.[selectedCorporateId]?.discountCodes;
        loyaltyProgramCodes = codesByCorporateId?.[selectedCorporateId]?.loyaltyProgramCodes;
      });

    if (this.ls.profilesType === 'airgateway') {
      this.corporateDiscountCodesService.setSelectedCorporate = this.selectedCorporateObj;
    } else if (this.ls.profilesType === 'umbrella') {
      this.corporateDiscountCodesService.setSelectedCorporate = this.selectedCompanyObj;
    }

    const providers = this.selectedPreset ? this.selectedPreset?.airline
                        : this.selectedProviders?.length && this.selectedProviders?.length !== this.helpers.allProviders?.length ? this.selectedProviders
                          : params?.owner;
    const qualifier = this.selectedPreset && this.selectedPreset.qualifier.type && this.selectedPreset.qualifier.code
                        ? this.selectedPreset.qualifier : null;

    const taxExemptions = this.selectedPreset?.taxExemptions?.map(taxExemption => taxExemption.code) || [];

    const today = new Date();
    const searchDate = today.getFullYear() + '-' + String(today.getMonth() + 1).padStart(2, '0')
      + '-' + String(today.getDate()).padStart(2, '0') + ' ' + today.getHours() + ":"
      + today.getMinutes();

    if (this.searchType === SEARCH_TYPE.REGULAR) {
      this.offerData = {
        searchDate: searchDate,
        flightType: this.form.controls['type'].value || this.ls.flightType,
        pax: travelersPrepared,
        originDestinations,
        cabin: cabin,
        specialDiscounts: selectedDiscounts,
        email: this.ls.email || '(not set)',
        preset: this.selectedPreset
      };
    }
    this.service
      .sendAirShopping(
        originDestinations,
        travelersPrepared,
        passengers,
        cabinTypes,
        selectedFare,
        hasDiscounts ? selectedDiscounts : null,
        currency,
        this.abortSignal,
        corporateSenderID,
        discountCodes,
        loyaltyProgramCodes,
        providers,
        qualifier,
        taxExemptions
      )
      .then(function (response) {
        that.service.lastSessionID = response.headers.get('Ag-Session-Id');
        that.service.lastRequestID = response.headers.get('Ag-Request-Id');
        that.service.updateCrispSessionData();

        that.gtmService.addEvent('ShoppingSearch');
        let reader = response.body.getReader();
        let decoder = new TextDecoder();

        if (response.status === 401) {
          that.keycloakService.getToken().then((token) => {
            if (token && that.ls.token !== token && !that.isTokenUpdated) {
              that.ls.token = token;
              that.isTokenUpdated = true;
              that.actionSearch();
              return;
            } else {
              that.authService.logout();
            }
          }).catch((err) => {
              that.authService.logout();
            });
        } else if (response.status === 403) {
          that._notificationSvc.error(ErrorTypes.InvalidAuth);
          // clearInterval(buildSidebarInterval);
          return;
        }
        that.service.lastSessionID = xhr.getResponseHeader('Ag-Session-Id');

        function search() {
          return reader.read().then(function (result) {
            let responseText = decoder.decode(
              result.value || new Uint8Array(),
              {
                stream: !result.done,
              }
            );
            if (partChunk) {
              responseText = partChunk + responseText;
            }
            let chunks = responseText.split('\n');
            partChunk = '';

            try {
              JSON.parse(chunks[chunks.length - 1]);
            } catch (e) {
              partChunk = chunks.pop();
            }
            chunks.forEach((chunk) => {
              let offer;
              if (!chunk.trim()) {
                return;
              }
              try {
                offer = JSON.parse(chunk);
              } catch (e) {
                console.error('JSON PARSE ERR:', chunk);
                return;
              }

              if (response.status >= 400 && offer.detail && offer.detail !== 'Expired JWT') {
                that.errorMessage = offer.detail;
                return;
              }

              if (response.status >= 400 && offer.error) {
                that.errorMessage = offer.error;
                return;
              }

              if (offer && offer.code && offer.provider && offer.detail) {
                if (offer.code === 'AGW_no_available_journey') {
                  if (!that.providerErrors.noResults) {
                    that.providerErrors.noResults = [];
                  }
                  that.providerErrors.noResults.push(offer.provider);
                } else {
                  if (!that.providerErrors[offer.provider]) {
                    that.providerErrors[offer.provider] = [];
                  }
                  that.providerErrors[offer.provider].push(offer.detail);
                }
                that.offerService.addProviderErrors(that.providerErrors);
              } else {
                if (!!offer.offerID) {
                  offers.push(offer);
                }
              }
            });

            if (result.done) {
              if (!that.isRequestSent) {
                that.isRequestSent = true;
              }
              that.isDoneSearch = true;
              that.emitIsSearching.emit(false);
              processOffers();
              // that.emitBuildDynamicSidebar.emit(that.collectSearchObject());
              //that.offerService.pushSearchModel(that.collectSearchObject());
              // clearInterval(buildSidebarInterval);
              that.offersSubscription.unsubscribe();

              that.allowNewSearch = true;
              that.isRequestSent = true;
              let endDate = new Date();
              that.loggedRequestTime = (
                (endDate.getTime() - startDate.getTime()) /
                1000
              ).toFixed(2);

              reader.cancel();
              return;
            }

            return search();
          });
        }

        return search();
      })
      .catch(function (err) {
        processOffers();
        that.emitIsSearching.emit(false);
        // that.emitBuildDynamicSidebar.emit(that.collectSearchObject());
        //that.offerService.pushSearchModel(that.collectSearchObject());
        // clearInterval(buildSidebarInterval);
        that.offersSubscription.unsubscribe();
        that.actionAbort();
        if (err.name !== 'AbortError') {
          console.log(err, err.message);
          that.errorMessage = err.message === 'network error' ?
            'Network problems: some search results may be missing, please retry again' :
            'Network Error - There was an undefined problem while connecting to AGW Servers';
        }
      });

  }

  getOriginDestinations() {
    const originDestinations: OriginDestination[] = [];
    let form = this.currentFormCopy;

    form =
      form === undefined ? JSON.parse(JSON.stringify(this.form.value)) : form;

    switch (form.type.toString()) {
      case Dictionary.FLIGHT_TYPE_ONE_WAY:
        originDestinations.push({
          departure: {
            airportCode: form[`departure_code[0]`],
            date: HelpersService.getFormattedDate(form['departure_date[0]']),
            time: this.helpers.getColonTime(this.selectedTime[0]),
            terminalName: '',
          },
          arrival: {
            airportCode: form[`arrival_code[0]`],
            time: '',
            terminalName: '',
          },
        });

        break;
      case Dictionary.FLIGHT_TYPE_ROUND_TRIP:
        originDestinations.push({
          departure: {
            airportCode: form[`departure_code[0]`],
            date: HelpersService.getFormattedDate(form['departure_date[0]']),
            time: this.helpers.getColonTime(this.selectedTime[0]),
            terminalName: '',
          },
          arrival: {
            airportCode: form[`arrival_code[0]`],
            time: '',
            terminalName: '',
          },
        });
        originDestinations.push({
          departure: {
            airportCode: form[`arrival_code[0]`],
            date: HelpersService.getFormattedDate(form['arrival_date[0]']),
            time: this.helpers.getColonTime(this.selectedTime[1]),
            terminalName: '',
          },
          arrival: {
            airportCode: form[`departure_code[0]`],
            time: '',
            terminalName: '',
          },
        });

        break;
      case Dictionary.FLIGHT_TYPE_MULTI_CITY:
        this.searchRowsCopy.map((row, index) => {
          originDestinations.push({
            departure: {
              airportCode: form[`departure_code[${row.controlIndex}]`],
              date: HelpersService.getFormattedDate(
                form[`departure_date[${row.controlIndex}]`]
              ),
              time: this.helpers.getColonTime(this.selectedTime[index]),
              terminalName: '',
            },
            arrival: {
              airportCode: form[`arrival_code[${row.controlIndex}]`],
              time: '',
              terminalName: '',
            },
          });
        });
        break;
    }

    return originDestinations.map((item) => {
      item.departure.airportCode = item.departure.airportCode
        ? item.departure.airportCode.toString().toUpperCase()
        : '';
      item.arrival.airportCode = item.arrival.airportCode
        ? item.arrival.airportCode.toString().toUpperCase()
        : '';
      return item;
    });
  }

  buildUrl(urlValues, resetUrl = false) {
    const originUrl = { ...this.route.snapshot.queryParams };

    this.removeFieldsFromObj(originUrl, 'origin_destinations_dep');
    this.removeFieldsFromObj(originUrl, 'origin_destinations_arr');
    this.removeFieldsFromObj(originUrl, 'origin_destinations_date');

    const fnTemplate = 'origin_destinations_flightnumbers';
    const odCount = urlValues.originDestinations.length;
    Object.keys(originUrl).map((key) => {
      if (key.match(fnTemplate)) {
        const tempIndex = +key.replace(`${fnTemplate}[`, '').replace(']', '');
        if (tempIndex + 1 > odCount) {
          originUrl[key] = undefined;
        }
      }
    });

    let params = {};
    params['type'] = urlValues.searchType;
    params[this.urlParams.MAX_STOPS] = urlValues.maxStops;
    Object.keys(urlValues.travelers).map((key, idx) => {
      const prefix = 'travelers_';
      params[prefix + key] = urlValues.travelers[key];
      const characterToAdd = idx !== Object.keys(urlValues.travelers).length - 1 ? '_' : '';
      this.travelersForOfferURL += urlValues.travelers[key] + characterToAdd;
    });
    params['ib_discount_residents'] = urlValues.ib_discount_residents;
    params['ib_discounts_large_family'] = urlValues.ib_discounts_large_family;
    // params['origin_destinations-flights'] = !!urlValues.flightsNumber ? urlValues.flightsNumber : '';
    this.selectedFlightNumberArr = !!urlValues.flightsNumber
      ? urlValues.flightsNumber.split('-')
      : [];
    params['cabin'] = urlValues.cabin;
    params['presetTitle'] = urlValues.presetTitle;
    if (urlValues.selectedProviders?.length) {
      params['providers'] = urlValues.selectedProviders.toString();
    } else {
      params['providers'] = null;
    }
    params['corporate_id'] = urlValues.corporate_id;
    params['selected_times'] = urlValues.selected_times || originUrl.selected_times;

    let maxIdx = 0;

    this.originDestinationsForOfferURL = '';
    let odItems = [];
    urlValues.originDestinations.map((item, idx) => {
      params[
        `origin_destinations_dep[${idx}]`
      ] = item.departure.airportCode.toString().toUpperCase();
      params[
        `origin_destinations_arr[${idx}]`
      ] = item.arrival.airportCode.toString().toUpperCase();
      params[`origin_destinations_date[${idx}]`] = item.departure.date;
      maxIdx = idx;
      odItems.push(
        item.departure.airportCode +
          ',' +
          item.arrival.airportCode +
          ',' +
          item.departure.date
      );
    });

    params['idx'] = maxIdx.toString();


    this.originDestinationsForOfferURL = odItems.join('_');

    if (this.searchClick) {
      params['departure_airports'] = undefined;
      params['arrival_airports'] = undefined;
      params[`fixed_origin_destinations`] = undefined;
    }

    params = { ...originUrl, ...params };

    if (resetUrl) {
      const obj = { ...ALL_RESET_KEYS_OBJ };
      Object.keys(params).map((param) => {
        Object.keys(obj).map((objItem) => {
          if (param.match(objItem)) {
            params[param] = obj[objItem];
          }
        });
      });
      if (!this.helpers.isPrivateMode) {
        this.dbService.clear('passengers_info')
          .subscribe((successDeleted) => {}, error => console.error(error));
      }
    }
    this.isFirstSearch = false;
    if (this.searchClick) {
      // this.removeFieldsFromObj(params, 'connections_black_list');
      // this.removeFieldsFromObj(params, 'connections_white_list');
      this.searchClick = false;
    }

    this.router.navigate([], { queryParams: params });
  }

  removeFieldsFromObj(obj, field: string) {
    Object.keys(obj)
      .filter((key) => key.match(field))
      .map((item) => {
        delete obj[item];
      });
  }

  actionAbort() {
    this.emitIsSearching.emit(false);
    if (this.abortController) {
      this.abortController.abort();
    }
    this.allowNewSearch = true;
    this.isRequestSent = false;
  }

  validate() {
    let depDateKey = 'departure_date[0]';
    let depDateCtl = this.form.controls[depDateKey];
    let retDateKey = 'arrival_date[0]';
    let retDateCtl = this.form.controls[retDateKey];
    let flightType = this.form.controls['type'].value;
    if (
      flightType === Dictionary.FLIGHT_TYPE_ROUND_TRIP &&
      (retDateCtl.value === null ||
        typeof retDateCtl.value === 'string' ||
        depDateCtl.value && HelpersService.isFirstDateGreater(depDateCtl.value, retDateCtl.value))
    ) {
      retDateCtl.setErrors({ invalidDate: true });
      return false;
    }
    if (!depDateCtl.value) {
      depDateCtl.setErrors({ invalidDate: true });
      return false;
    }

    let depCodeKey = 'departure_code[0]';
    let depCodeCtl = this.form.controls[depCodeKey];
    let arrCodeKey = 'arrival_code[0]';
    let arrCodeCtl = this.form.controls[arrCodeKey];
    if (!depCodeCtl.value) {
      depCodeCtl.setErrors({ invalidDate: true });
    }
    if (!arrCodeCtl.value) {
      arrCodeCtl.setErrors({ invalidDate: true });
    }
    if (!depCodeCtl.value || !arrCodeCtl.value) {
      return false;
    }

    retDateCtl.setErrors(null);
    depCodeCtl.setErrors(null);
    arrCodeCtl.setErrors(null);
    return true;
  }

  multiCityDateValidator() {
    if (this.form.get('type')?.value !== Dictionary.FLIGHT_TYPE_MULTI_CITY) {
      return true;
    }

    let valid = true;
    const depPrefix = 'departure_date[';

    for (let i = 1; i < this.searchRows.length; i++) {
      const currentControl = this.form.get(`${depPrefix}${this.searchRows[i].controlIndex}]`);
      const previousControl = this.form.get(`${depPrefix}${this.searchRows[i - 1].controlIndex}]`);
      if (!currentControl?.value?.year) {
        this.validateTriggered = true;
        valid = false;
        return;
      }

      const date = currentControl.value;
      const checkDate = previousControl?.value;

      const dateValue = moment(`${date.year}-${date.month}-${date.day}`, 'YYYY-MM-DD').unix();
      const checkDateValue = moment(`${checkDate.year}-${checkDate.month}-${checkDate.day}`, 'YYYY-MM-DD').unix();

      if (!previousControl?.valid || (dateValue !== checkDateValue && dateValue < checkDateValue)) {
        currentControl.setErrors({ invalid: true });
        this.validateTriggered = true;
        valid = false;
      } else {
        currentControl.setErrors(null);
      }
    }

    return valid;
  }

  onDateChanged(dateString: string, rowIndex: number, index: number, type: string) {
    const parsedDate = this.dateService.formatDate(dateString);

    const minDate = this.minDates[`${type}_date[${rowIndex}]`] || this.currentDate;

    if (parsedDate.isValid()) {
      const formattedDate = this.dateService.getFormattedDate(parsedDate);
      const isValid = this.dateService.validateDate(formattedDate, minDate);

      if (isValid) {
        if (type === 'departure') {
          this.onDateDepartureSelect(rowIndex, index, formattedDate, false);
        }
        this.form.get(`${type}_date[${rowIndex}]`).patchValue(formattedDate);
      } else {
        this.form.get(`${type}_date[${rowIndex}]`).setErrors({ngbDate: { minDate: true }});
      }
    }
  }

  onDateDepartureSelect(rowIndex: number, index: number, value: NgbDateStruct, openCalendar = true) {
    let depPrefix = 'departure_date[';
    let arrPrefix = 'arrival_date[';

    let nextDateObj: NgbDateStruct = HelpersService.createStructWithChangeDays(
      value,
      3
    );

    switch (this.form.controls['type'].value) {
      case Dictionary.FLIGHT_TYPE_ONE_WAY:
        this.resetMinMaxDates();
        break;
      case Dictionary.FLIGHT_TYPE_ROUND_TRIP:
        const date = this.form.get(`${arrPrefix}${rowIndex}]`).value;
        const firstDateOD = moment(
          moment(`${value.year}-${value.month}-${value.day}`, 'YYYY-MM-DD')
        ).unix();
        const secondDateOD = moment(
          moment(`${date?.year}-${date?.month}-${date?.day}`, 'YYYY-MM-DD')
        ).unix();

        if (openCalendar && firstDateOD && !secondDateOD && this.dd) {
          this.dd.toggle();
        }

        if (firstDateOD > secondDateOD) {
          this.form.get(`${arrPrefix}${rowIndex}]`).setValue(nextDateObj);
        }
        this.minDates[`${arrPrefix}${rowIndex}]`] = value;
        break;
      case Dictionary.FLIGHT_TYPE_MULTI_CITY:
        if (this.minDates.hasOwnProperty(`${depPrefix}${this.searchRows[index + 1]?.controlIndex}]`)) {
          this.minDates[`${depPrefix}${this.searchRows[index + 1].controlIndex}]`] = value;
        }
        const currentDate = this.form.get(`${depPrefix}${rowIndex}]`).value;
        const currentDateInSeconds = moment(
          moment(`${currentDate.year}-${currentDate.month}-${currentDate.day}`, 'YYYY-MM-DD')
        ).unix();

        this.searchRows.map(row => {
          if (row.controlIndex > rowIndex) {
            const nextDate = this.form.get(`${depPrefix}${row.controlIndex}]`).value;
            const nextDateInSeconds = moment(
              moment(`${nextDate.year}-${nextDate.month}-${nextDate.day}`, 'YYYY-MM-DD')
            ).unix();
            if (currentDateInSeconds > nextDateInSeconds) {
              this.form.get(`${depPrefix}${row.controlIndex}]`).setValue('');
            }
          } else {
            const previousDate = this.form.get(`${depPrefix}${row.controlIndex - 1}]`)?.value;
            if (previousDate) {
              if (HelpersService.isFirstDateGreater(previousDate, currentDate)) {
                this.form.get(`${depPrefix}${rowIndex}]`).setValue(previousDate);
              }
            }
          }
        });
    }

    // this.multiCityDateValidator();
  }

  setMinMaxDates() {
    if (this.form.controls['type'].value === FLIGHT_TYPE.RT) {
      this.minDates[`arrival_date[0]`] = this.form.controls[`departure_date[0]`].value;
    } else if (this.form.controls['type'].value === FLIGHT_TYPE.MC) {
      for (let i = 0; i < this.searchRows.length; i++) {
        if (this.form.controls[`departure_date[${i - 1}]`]?.value) {
          this.minDates[`departure_date[${i}]`] = this.form.controls[`departure_date[${i - 1}]`].value;
        }
      }
    }
  }

  resetMinMaxDates() {
    this.minDates = {
      'departure_date[0]': this.defaultMinDate,
    };
  }

  actionRemoveRow(index) {
    let controlIndex = this.searchRows[index].controlIndex;
    let depPrefix = 'departure_date[';

    this.searchRows.splice(index, 1);
    this.updateFormRows(controlIndex, 'remove');
    if (this.searchRows[index]?.controlIndex !== undefined && this.searchRows[index - 1]?.controlIndex !== undefined) {
      this.minDates[`${depPrefix}${this.searchRows[index].controlIndex}]`] = this.form.controls[`${depPrefix}${this.searchRows[index - 1].controlIndex}]`].value;
    }
  }

  actionSwap(controlIndex) {
    let depCode = this.form.controls[`departure_code[${controlIndex}]`].value;
    let arrCode = this.form.controls[`arrival_code[${controlIndex}]`].value;
    this.form.controls[`departure_code[${controlIndex}]`].setValue(arrCode);
    this.form.controls[`arrival_code[${controlIndex}]`].setValue(depCode);
  }

  private collectSearchObject(): SearchModel {
    const searchObject: SearchModel = {
      originDestinations: this.getOriginDestinations(),
      defaultMinDate: this.defaultMinDate,
      defaultDepartureDate: this.defaultDepartureDate,
      defaultReturnDate: this.defaultReturnDate,
      minDates: this.minDates,
      totalOffers: this.totalOffers,
      originDestinationsForOfferURL: this.originDestinationsForOfferURL,
      travelersForOfferURL: this.travelersForOfferURL,
      loggedRequestTime: this.loggedRequestTime,
      errorMessage: this.errorMessage,
      allowNewSearch: this.allowNewSearch,
      isRequestSent: this.isRequestSent,
      selectedFlightNumberArr: this.getODFlightNumbers(),
      form: {
        type: this.form.controls['type'].value.toString(),
        departure_code_0: this.form.controls['departure_code[0]'].value,
        arrival_code_0: this.form.controls['arrival_code[0]'].value,
        cabin: this.form.controls['cabin'].value,
      },
      selectedTime: this.selectedTime
    };

    return searchObject;
  }

  private getODFlightNumbers(): Object {
    let params = this.route.snapshot.queryParams;
    const tempValue = {};
    const template = 'origin_destinations_flightnumbers';
    Object.keys(params).map((key) => {
      if (key.match(template)) {
        const index = key.replace(`${template}[`, '').replace(']', '');
        tempValue[+index] = !!params[key] ? params[key].split(',') : [];
      }
    });
    return tempValue;
  }

  setFlightType(type) {
    this.form.controls['type'].setValue(type);
    this.flightType = this.form.controls['type'].value;
    this.ls.flightType = type;
    if (type === 'OW' || type === 'RT') {
      this.searchRows = this.searchRows.slice(0, 1);
    }
    this.resetMinMaxDates();
    this.setMinMaxDates();
  }

  private replacePastDateToCurrent(date: string): string {
    return date &&
      moment(date).unix() < moment(moment().format('YYYY-MM-DD')).unix()
      ? moment().format('YYYY-MM-DD')
      : date;
  }

  onUpdateOffersLater() {
    this.isShowUpdatePopup = false;
  }

  onUpdateOffers() {
    this.showUpdateSpinner = true;
    setTimeout(() => {
      this.emitRefreshOffers.emit(true);
      this.isShowUpdatePopup = false;
    }, 500);
  }

  resetPreset() {
    this.selectedPreset = null;
    this.buildTravelers();
    this.setDefaultPtcValues();
    this.isNewPtc = this.isAddPassengerButtonDisable = this.isAddInfantButtonDisable = false;
    this.travelersCount = 1;
    this.helpers.presetTitle = '';
  }

  setDefaultPtcValues() {
    Object.keys(this.ptcsMap).map(key => {
      this.ptcsMap[key] = key.toLowerCase();
    });
  }

  onSelectedPreset(preset) {
    this.selectedPreset = preset;
    this.prepareTravelersBasedOnSelectedPreset();
    this.isAddPassengerButtonDisable = this.isAddInfantButtonDisable = false;
  }

  prepareTravelersBasedOnSelectedPreset() {
    this.selectedPreset.travelers.map(traveler => {
      this.ptcsMap[traveler.defaultType] = traveler.travelerType.toLowerCase();
    });
    this.isNewPtc = this.selectedPreset.travelers.length !== Object.keys(this.defaultTravelers).length
                              || !this.selectedPreset.travelers.every(t => this.defaultTravelers.hasOwnProperty(t.travelerType.toLowerCase()));
    if (this.isNewPtc) {
      this.buildTravelers(false);
    } else {
      this.buildTravelers();
    }
    this.travelersCount = 1;
    this.helpers.presetTitle = this.selectedPreset.title;
  }

  buildTravelers(isDefaultTravelers = true) {
    this.form.setControl('travelers', isDefaultTravelers ? this.defaultTravelersFormGroup() : this.newTravelersFormGroup());
  }

  newTravelersFormGroup() {
    let controlsConfig = {};
    this.selectedPreset.travelers.map(traveler => {
      const travelerType = traveler.travelerType.toLowerCase();
      controlsConfig[travelerType] = travelerType === this.ptcsMap.ADT ? [1] : [0];
    });
    return this.fb.group(controlsConfig);
  }

  open(content, size: any = 'lg') {
    this.modalService.open(content, {
      size: size,
      windowClass: 'modal-pnr'
    }).result.then((result) => {
    }, (reason) => {
    });
  }

  addOfferToHistory(data, searchType) {
    if (data && !this.helpers.isPrivateMode) {
      let allowToAdd = true;
      let offerId;
      let dbData;
      if (searchType === SEARCH_TYPE.REGULAR) {
        this.webOffersHistoryData.map(offer => {
          if (offer.cabin === data.cabin && offer.flight_type === data.flightType
            && JSON.stringify(data.specialDiscounts) === JSON.stringify(offer.special_discounts)
            && JSON.stringify(data.pax) === JSON.stringify(offer.pax)
            && JSON.stringify(data.originDestinations) === JSON.stringify(offer.origin_destination)
            && offer.email === data.email) {
            offerId = offer.id;
            allowToAdd = false;
          }
        });
        if (allowToAdd) {
           dbData = this.sentryService.lastIndexedDbDataToBeAdded = {
            search_date: data.searchDate,
            flight_type: data.flightType,
            pax: data.pax,
            origin_destination: data.originDestinations,
            cabin: data.cabin,
            special_discounts: data.specialDiscounts,
            email: data.email,
            preset: data.preset
          };
          this.sentryService.lastIndexedDbDataToBeAdded['operation'] = 'ADD WEB OFFER HISTORY TABLE';

          this.dbService
            .add('web_offer_history', dbData)
            .subscribe((data) => {
              this.webOffersHistoryData.push(data);
            }, error => console.error(error));
        } else {
          dbData = this.sentryService.lastIndexedDbDataToBeAdded = {
            id: offerId,
            search_date: data.searchDate,
            flight_type: data.flightType,
            pax: data.pax,
            origin_destination: data.originDestinations,
            cabin: data.cabin,
            special_discounts: data.specialDiscounts,
            email: data.email,
            preset: data.preset
          };
          this.sentryService.lastIndexedDbDataToBeAdded['operation'] = 'UPDATE WEB OFFER HISTORY TABLE';

          this.dbService.update('web_offer_history', dbData)
            .subscribe((storeData) => {
              this.webOffersHistoryData = storeData;
            }, error => console.error(error));
        }
      }
    }
  }

  getAllOffersAndRemoveOldHistory() {
    if (!this.helpers.isPrivateMode) {
      this.getWebHistory();
    }
  }

  getWebHistory() {
    this.dbService.getAll('web_offer_history')
      .pipe(take(1))
      .subscribe((data) => {
        this.webOffersHistoryData = data;
        this.addEmailToExistingOffersInWebHistory();

        if (+this.ls.currentDate !== +new Date().getDate()) {
          this.deleteOldOfferFromWebHistory();
          this.ls.currentDate = new Date().getDate();
        }
    }, error => console.error(error));
  }

  addEmailToExistingOffersInWebHistory() {
    this.webOffersHistoryData.map(offer => {
      if (!offer.email) {
        const dbData = this.sentryService.lastIndexedDbDataToBeAdded = {
          id: offer.id,
          search_date: offer.search_date,
          flight_type: offer.flight_type,
          pax: offer.pax,
          origin_destination: offer.origin_destination,
          cabin: offer.cabin,
          special_discounts: offer.special_discounts,
          email: this.ls.email || '(not set)'
        };
        this.sentryService.lastIndexedDbDataToBeAdded['operation'] = 'ADD EMAIL WEB OFFER HISTORY TABLE';

        this.dbService.update('web_offer_history', dbData)
        .subscribe((storeData) => {
            this.webOffersHistoryData = storeData;
          }, error => console.error(error));
      }
    });
  }

  deleteOldOfferFromWebHistory() {
    this.webOffersHistoryData.map(offer => {
      const currentDate = new Date();
      const offerDate = new Date(offer['search_date']);
      let differenceBetweenDates = Math.floor((Date.UTC(offerDate.getFullYear(), offerDate.getMonth(), offerDate.getDate()) - Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) / (1000 * 60 * 60 * 24));
      if (differenceBetweenDates < -30) {
        this.dbService.delete('web_offer_history', offer['id'])
          .pipe(take(1))
          .subscribe((remainingData) => this.webOffersHistoryData = remainingData, error => console.error(error));
      }
    });
  }

  onRemoveOffer(offer) {
    if (offer.searchType === SEARCH_TYPE.REGULAR) {
      this.dbService.delete('web_offer_history', offer.id).subscribe((remainingData) => this.webOffersHistoryData = remainingData.reverse(), error => console.error(error));
    }
  }

  onSearchOffer(offer) {
    if (this.searchType === SEARCH_TYPE.REGULAR) {
      if (offer.preset) {
        this.selectedPreset = this.hubAgenciesService.presets?.find(p => p.title === offer.preset?.title) || offer.preset;
        this.prepareTravelersBasedOnSelectedPreset();
      } else {
        this.resetPreset();
      }
      this.searchRows = [];
      this.form.get('cabin').setValue(offer.cabin);
      this.form.get('type').setValue(offer.flight_type);
      const travelers = this.form.get('travelers');
      // checking if all traveler types currently exist that were previously used in the search
      const allTravelerTypesExist = Object.keys(offer.pax).every(key => travelers.value.hasOwnProperty(key));
      if (allTravelerTypesExist) {
        for (const [key, value] of Object.entries(offer.pax)) {
          travelers.get(key).setValue(value);
        }
      } else {
        // if types have changed, but there is a new name for the type that was used in the search,
        // then set value to the new(current) type
        offer.preset?.travelers?.map(traveler => {
          const currentTravelerType = this.ptcsMap[traveler.defaultType];
          const value = offer.pax[traveler.travelerType.toLowerCase()];
          if (travelers.get(currentTravelerType)) {
            travelers.get(currentTravelerType).setValue(value);
          }
        });
      }
      this.actionChangeTravelers();
      this.form.get('residents').setValue(offer.special_discounts.residentCode || '');
      this.form.get('large_family').setValue(offer.special_discounts.largeFamily || '');

      let parts = {};
      let date = {};

      const flightType = this.form.controls['type'].value;
      if (flightType === FLIGHT_TYPE.OW || flightType === FLIGHT_TYPE.RT) {
        this.form.controls[`arrival_code[0]`].setValue(offer.origin_destination[0].arrival.airportCode);
        this.form.controls[`departure_code[0]`].setValue(offer.origin_destination[0].departure.airportCode);
        this.searchRows.push({ controlIndex: 0 });

        offer.origin_destination.map((of, i) => {
          parts[i] = of.departure.date.split('-');
          date[i] = new NgbDate(+parts[i][0], +parts[i][1], +parts[i][2]);
        });
        if (offer.origin_destination.length === 1) {
          this.form.controls[`departure_date[0]`].setValue(date[0]);
          this.form.controls[`arrival_date[0]`].setValue(date[0]);
        } else if (offer.origin_destination.length === 2) {
          this.form.controls[`departure_date[0]`].setValue(date[0]);
          this.form.controls[`arrival_date[0]`].setValue(date[1]);
        }
      } else if (flightType === FLIGHT_TYPE.MC) {
        offer.origin_destination.map((of, i) => {
          parts[i] = of.departure.date.split('-');
          date[i] = new NgbDate(+parts[i][0], +parts[i][1], +parts[i][2]);
          this.form['addControl'](
            `arrival_code[${i}]`,
            new FormControl(of.arrival.airportCode, [])
          );
          this.form['addControl'](
            `departure_code[${i}]`,
            new FormControl(of.departure.airportCode, [])
          );
          this.form['addControl'](
            `arrival_date[${i}]`,
            new FormControl(of.arrival.date, [])
          );
          this.form['addControl'](
            `departure_date[${i}]`,
            new FormControl(of.departure.date, [])
          );

          this.form.controls[`arrival_code[${i}]`].setValue(offer.origin_destination[i].arrival.airportCode);
          this.form.controls[`departure_code[${i}]`].setValue(offer.origin_destination[i].departure.airportCode);
          this.form.controls[`arrival_date[${i}]`].setValue(date[i]);
          this.form.controls[`departure_date[${i}]`].setValue(date[i]);
          this.searchRows.push(<SearchRow>{ controlIndex: i });
        });
      }
    }
  }

  getUpdatedDateFormat() {
    this.dateService.getDateFormat().subscribe((format) => {
      if (this.departureDates && this.arrivalDate) {
        this.updateDepartureDates(format);
        this.updateArrivalDate(format);
      } else {
        if (this.departureDates) {
          this.updateDepartureDates(format);
        }
      }
    });
  }

  updateDepartureDates(format: string) {
    this.departureDates?.forEach((element) => {
      if (!this.helpers.formatDate(element.nativeElement.value).includes('NaN')) {
        element.nativeElement.value = moment(this.helpers.formatDate(element.nativeElement.value)).format(format).toUpperCase();
      }
    });
  }

  updateArrivalDate(format: string) {
    if (!this.helpers.formatDate(this.arrivalDate.nativeElement.value).includes('NaN')) {
      this.arrivalDate.nativeElement.value = moment(this.helpers.formatDate(this.arrivalDate.nativeElement.value)).format(format).toUpperCase();
    }
  }

  setProviders(provider: string) {
    if (this.selectedProviders.includes(provider)) {
      this.selectedProviders.splice(this.selectedProviders.indexOf(provider), 1);
    } else {
      this.selectedProviders.push(provider);
    }
    this.ls.providers = this.selectedProviders;
  }

  selectOneProvider(provider: string) {
    this.selectedProviders = [provider];
    this.ls.providers = this.selectedProviders;
  }

  clearProviders() {
    this.selectedProviders = [];
    this.ls.providers = this.selectedProviders;
  }

  refreshPresetList() {
    this.presetListRefreshToggled = !this.presetListRefreshToggled;
    this.hubAgenciesService.refreshPresets();
  }

}
